header {
    height: 54px;
}

.MuiToolbar-root {
    height: 54px !important;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

body {
    scroll-behavior: smooth;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.app-bar {
    background-color: black;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.displayFlexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dialogHeader {
    padding: 14px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dialogFooter {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-left: 4px;
}

.setProperties {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
}

.cursor {
    cursor: pointer;
}

.liveSearchButtons {
    display: flex;
    justify-content: start;

}

.liveSearchButtons>* {
    margin-right: 8px;
}

.filterChips {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    max-height: 250px;
}

.filterChips>* {
    padding: 5px;
}

@media (min-width: 1200px) {
    .MuiContainer-root {
        max-width: 1300px !important;
    }
}

.inputFilters {
    max-width: 150px;
    margin-right: 5px !important;
    margin-left: 5px !important;
}

.resetBtn {
    height: 20px;
}

.imageView {
    transition: transform 0.3s ease-in-out;
}

.imageView:hover {
    opacity: .8;
    cursor: pointer;
    transform: scale(0.98);
}

.viewImage {
    background: transparent;
}

.cardLink {
    text-decoration: none;
    cursor: unset;
    transition: all .3s ease-in-out !important;
    border-radius: 5px;
}

.cardLink:hover {
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgb(1, 11, 51);
}

.mapItem {
    text-decoration: none;
    cursor: pointer;
    transition: all .3s ease-in-out !important;
    color: black;
}

.mapItem:hover {
    opacity: 0.8;
}

.borderShadow:hover {
    box-shadow: 0px 0px 2px rgba(138, 138, 138, 0.6);
}

.optionEmphasis {
    transition: all 0.4s ease;
    color: black;
}

.optionEmphasis:hover {
    color: #EB5A2D;
}

.optionHover {
    color: inherit;
    transition: all 0.4s ease;
}

.optionHover:hover {
    color: #EB5A2D;
}

.paymentInput {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    padding: .75rem;
    margin: 15px 0px;
}

.loanFormImputs {
    padding: 4px;
    margin: 5px 0px;
    border: solid 1px grey;
}

.loanForm .loanFormImputs {
    width: 80px;
}

.formRow {
    align-items: center;
}

.formNumber {
    background-color: #233C90;
    color: white;
    width: 20px;
    height: 20px;
    display: flex;
    font-size: 0.8rem;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
}

.realtorInfo {
    border: solid 1px #80808040;
    border-radius: 5px;
    margin: 5px;
    padding: 10px;
}

.matchFields {
    border: solid 1px #80808040;
    padding: 10px;
    border-radius: 5px;
}

.coBorrowerInfo {
    border: solid 1px #80808040;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
}

.borrowerInfo {
    border: solid 1px #80808040;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
}

.inputError {
    border: solid 2px #d32f2f !important;
}

.coverageMap {
    width: 100%;
    background-color: #233C90;
}