/* Provide sufficient contrast against white background */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body,
a,
span,
p,
div {
  font-family: "Inter", sans-serif;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: whitesmoke;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  height: 8px;
  background-color: #b1b1b1;
}

#root {
  position: absolute;
  width: 100%;
  top: 0;
  line-height: 1.4 !important;
}